//
// Toasts
// --------------------------------------------------


.toast {
  --#{$prefix}toast-header-font-size: #{$toast-header-font-size};
}

.toast-header {
  position: relative;
  border: 0;
  font: {
    size: var(--#{$prefix}toast-header-font-size);
    weight: var(--#{$prefix}heading-font-weight);
  }

  &::after {
    position: absolute;
    left: var(--#{$prefix}toast-padding-x);
    bottom: calc(var(--#{$prefix}toast-border-width) * -1);
    width: calc(100% - (var(--#{$prefix}toast-padding-x) * 2));
    height: var(--#{$prefix}toast-border-width);
    background-color: var(--#{$prefix}toast-header-border-color);
    content: '';
  }

  &[class^='bg-']::after,
  &[class*=' bg-']::after {
    display: none;
  }
  .btn-close {
    font-size: .875em;
    margin-right: 0;
    padding-right: 0;
  }
}

.toast-body {
  padding-top: $toast-padding-y * 1.1;
}
