//
// Any content that can be binded to nav, accordion or carousel
// ------------------------------------------------------------

.binded-content {
  position: relative;
  height: 100%;
}

.binded-item {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity .35s ease-in-out, visibility .35s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.active {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
}
